import { render, staticRenderFns } from "./NewsMessage.vue?vue&type=template&id=3983ef09&scoped=true"
import script from "./NewsMessage.vue?vue&type=script&lang=js"
export * from "./NewsMessage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3983ef09",
  null
  
)

export default component.exports